import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import CheckoutSteps from '../components/CheckoutSteps'
import LoadingButton from '../components/loadingButton';
import { removeAllCart } from '../actions/cartItemAction';
import { Card, Button, CardContent, CircularProgress, ListItem, ListItemAvatar, Typography, ListItemText, List, ListItemSecondaryAction } from "@material-ui/core";
import Empty from '../components/emptyList';
import config from '../config'
import { Console } from "../console";
import * as Colors from '../colors'
import { generateOrderNumber } from '../util'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
const ship = { address: '', country: '', city: '' }
const pay = { paymentMethod: '' }

function PlaceOrderScreen(props) {
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState(null);
  const [note, setNote] = useState('')
  const [cuponError, setCuponError] = useState(null)
  const [couponId, setCouponId] = useState(null)
  const [couponAmount, setcouponAmount] = useState(0)
  const [couponLoading, setCouponLoading] = useState(false)
  const cart = useSelector(state => state.cartItem);
  const user = useSelector(state => state.userSignin)
  const { fee } = useSelector(state => state.fees)
  const [shipAmount, setShipAmount] = useState(fee.length > 0 ? fee[0].amount : 0)
  const [checkedIndex, setCheckedIndex] = useState(0)
  Console(`Get shipping feee ${fee}`).log()
  useEffect(() => {
    if (!user.userInfo) {
      props.history.push('/signin')
    }
  }, [user, props])
  const { name, phone } = user.userInfo ? user.userInfo : { name: '', phone: '' }
  const { items = [], shipping = ship, payment = pay } = cart;
  const dispatch = useDispatch()
  if (shipping === null) {
    props.history.push("/shipping");
  }
  if (!payment.paymentMethod) {
    props.history.push("/payment");
  }

  const checkoutHandler = async () => {
    setDisable(true)
    let totalAmount = 0, totalAddedTaxAmount = 0, totalNetAmount = 0, taxIds = [], totalEarnPoints = 0
    const _items = items.map((i, index) => {
      const { unit } = i
      totalAmount += i.totalAmount
      totalAddedTaxAmount += i.addedTaxAmount
      totalNetAmount += i.totalNetAmount
      if (Array.isArray(i.applicableTaxIds)) {
        taxIds = [...i.applicableTaxIds]
      }
      let itemPoint = 0
      if (i.unitPoints) {
        itemPoint = i.qty * i.unitPoints
      }
      totalEarnPoints = totalEarnPoints + itemPoint
      //toal amount => total amount value without taxes (qty * price)
      return {
        addedTaxAmount: 0,
        name: i.name,
        unitPoints: itemPoint,
        optionName: i.variationName,
        itemVariationId: i.variationId,
        price: i.price,
        photo: i.photo,
        netAmount: i.totalAmount,
        currency: 'MMK',
        inclusiveTaxAmount: 0,
        index: index,
        totalNetAmount: i.totalAmount,
        totalAmount: i.totalAmount,
        taxIds: i.applicableTaxIds || [],
        qty: { value: i.qty, precision: unit.precision, suffix: "" }
      }
    })
    const checkoutfunc = firebase.functions().httpsCallable('posCheckoutWeb');
    const result = await checkoutfunc({
      merchantId: config.merchantId,
      customer:
      {
        name: name,
        phone: phone,
        address: shipping.address,
        payment: payment.paymentMethod
      },
      detail: _items,
      other: {
        orderType: 'Web',
        paymentType: 'Cash',
        totalAddedTaxAmount: totalAddedTaxAmount,
        totalAmount: totalNetAmount + parseInt(shipAmount, 10) - couponAmount,
        discount: {},
        orderStatus: 'Pending',
        earnPoints: totalEarnPoints,
        currency: 'MMK',
        totalInclusiveTaxAmount: 0,
        totalDiscountAmount: couponAmount,
        shippingAmount: parseInt(shipAmount, 10),
        couponAmount: couponAmount,
        couponId: couponId || '',
        totalNetAmount: totalAmount,
        orderId: generateOrderNumber(),
        taxIds: taxIds,
        notes: note,
      },
    });
    const checkoutResult = result.data;
    if (checkoutResult.success) {
      //  console.log('checkout success', checkoutResult)
      dispatch(removeAllCart())
      props.history.push('/thank')
    } else {
      setError(checkoutResult.message)
    }
  }
  const shippFeeHandler = (e, index) => {
    if (couponAmount > 0) return
    setCheckedIndex(index)
    setShipAmount(e.target.value)
  }
  let totalAmount = 0, totalTaxAmount = 0
  items.map(i => totalTaxAmount += i.addedTaxAmount)
  items.map(i => totalAmount += i.totalNetAmount)
  totalAmount = totalAmount + parseInt(shipAmount, 0)
  const couponHandler = async () => {
    if (couponId === null || (typeof couponId === 'string' && couponId.length <= 0)) {
      setCuponError('Invalid Cupon Code')
      return
    }
    setCouponLoading(true)
    const couponFunc = firebase.functions().httpsCallable('getCoupon')
    const result = await couponFunc({
      merchantId: config.merchantId,
      memberIdOrPhoneNo: user.userInfo.phone,
      amount: totalAmount,
      couponId: couponId.replace(/\s/g, '')
    })
    if (result.data.status === "success") {
      const { unit, value } = result.data.data
      if (unit === 'percentage') {
        setcouponAmount(totalAmount * (value / 100))
      } else {
        setcouponAmount(value)
      }
    } else {
      setCuponError(result.data.message)
    }
    setCouponLoading(false)
  }
  return <div>
    <CheckoutSteps step1 step2 step3 step4 ></CheckoutSteps>
    <div className="placeorder">
      <div className="placeorder-info">
        <Card>
          <h3>
            Shipping
          </h3>
          {
            shipping ?
              <div>
                {shipping.address}, {shipping.city},
          {shipping.country},
          </div> :
              <div />
          }

        </Card>
        <Card>
          <h3>Payment</h3>
          <div>
            Payment Method: {cart.payment.paymentMethod}
          </div>
        </Card>
        <Card>
          <ul className="cart-list-container">
            <li>
              <h3>
                Shopping Cart
          </h3>
              <div>
                Price
          </div>
            </li>
            {
              items.length === 0 ?
                <Empty />
                :
                items.map(item =>
                  <li>
                    <div className="cart-image">
                      <img src={item.photo} alt="product" />
                    </div>
                    <div className="cart-name" >
                      <div>
                        <Link to={"/thank"}>
                          {item.name}
                        </Link>

                      </div>
                      <div style={{ marginTop: '10px' }}>
                        <i style={{ color: Colors.colors.variation }}>{item.variationName}</i>
                      </div>
                      <div style={{ marginTop: '10px' }}>
                        Qty: {item.qty}
                      </div>
                    </div>
                    <div className="cart-price">
                      <b style={{ fontSize: 14, fontWeight: 'bold' }}>  {item.price} {item.currency}</b>
                    </div>
                  </li>
                )
            }
          </ul>
        </Card>
      </div>
      <Card className="placeorder-action">
        {error && <div style={{ color: 'red' }}> {error}</div>}
        <ul>

          <li>
            {
              disable ? <LoadingButton /> :
                <button
                  className="button primary full-width"
                  onClick={() => checkoutHandler()}
                  disabled={items.length <= 0} >
                  <b style={{ color: 'white' }}>
                    Checkout</b>
                </button>
            }
          </li>
          <li>
            {fee.length > 0 ?
              < Card style={{ width: '100%' }}>
                <CardContent>
                  <Typography gutterBottom>
                    <h2 style={{ fontWeight: 'bold' }}> Shipping Fees</h2>
                  </Typography>
                  <List>
                    {
                      fee.map((f, index) =>
                        <ListItem style={{ padding: 0 }}>
                          <ListItemAvatar>
                            <input
                              type="radio"
                              name="shippingMethod"
                              // id='shppignMethod'
                              value={f.amount}
                              onChange={(e) => shippFeeHandler(e, index)}
                              checked={index === checkedIndex ? true : false}
                            ></input>
                          </ListItemAvatar>
                          <ListItemText style={{ maxWidth: '150px' }}>
                            <span>
                              <b style={{ fontWeight: 'bold', color: 'black', fontSize: 10 }} >{f.location}</b></span></ListItemText>
                          <ListItemSecondaryAction>
                            <b style={{ fontWeight: 'bold', color: 'black', fontSize: 14, right: 0 }}> {f.amount > 0 ? `${f.amount} MMK` : 'Free'} </b>
                          </ListItemSecondaryAction>
                        </ListItem>)
                    }
                  </List>
                </CardContent>
              </Card> : <div />}
          </li>
          <li>
            <Card style={{ width: '100%' }}>
              <CardContent>
                <Typography gutterBottom>
                  <h2 style={{ fontWeight: 'bold' }}> Notes</h2>
                </Typography>
                <TextareaAutosize
                  aria-label="minimum height"
                  rowsMin={3}
                  onChange={(e) => setNote(e.target.value)}
                  style={{ width: '100%', borderColor: 'whitesmoke' }}
                  placeholder="Enter notes" />
              </CardContent>
            </Card>
          </li>
          <li>
            < Card style={{ width: '100%' }}>
              {couponAmount > 0 ?
                <Typography gutterBottom>
                  <h4 style={{ fontWeight: 'bold', color: 'green', textAlign: 'center' }}> You got {couponAmount} MMK</h4>
                </Typography> : cuponError && <Typography gutterBottom>
                  <h4 style={{ fontWeight: 'bold', color: 'red', textAlign: 'center' }}> {cuponError}</h4>
                </Typography>
              }
              <CardContent>
                <input type="text" name="cupon" id="cupon" onChange={(e) => setCouponId(e.target.value)} placeholder='Enter coupon code' readOnly={couponAmount > 0 ? true : false} />
                {couponLoading ? <CircularProgress color='primary' size={30} style={{ marginLeft: 12 }} /> :
                  <Button onClick={() => couponAmount > 0 ? {} : couponHandler()} size='medium' style={{ color: Colors.colors.primary, marginLeft: 5, fontWeight: 'bold' }}>Get Coupon</Button>
                }
              </CardContent>
            </Card>
          </li>
          <li>
            <Card style={{ width: '100%' }}>
              <CardContent>
                <Typography gutterBottom>
                  <h2 style={{ fontWeight: 'bold' }}> Summary</h2>
                </Typography>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <b style={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>Items </b>
                    </ListItemAvatar>
                    <ListItemSecondaryAction>
                      <b style={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}> {totalAmount - totalTaxAmount} MMK</b>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <b style={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>  Tax</b>
                    </ListItemAvatar>
                    <ListItemSecondaryAction>
                      <b style={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>   {totalTaxAmount} MMK</b>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <b style={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>   Shipping</b>
                    </ListItemAvatar>
                    <ListItemSecondaryAction>
                      <b style={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>    {shipAmount} MMK</b>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <b style={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>   Coupon </b>
                    </ListItemAvatar>
                    <ListItemSecondaryAction>
                      <b style={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>  {couponAmount} MMK</b>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <b style={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>   Total</b>
                    </ListItemAvatar>
                    <ListItemSecondaryAction>
                      <b style={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}> {totalAmount - couponAmount} MMK</b>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </li>
        </ul>
      </Card>
    </div>
  </div >

}

export default PlaceOrderScreen;